@import "core/index.scss";
.page.brands {
	padding: 4rem 0;
}

.section.brands-featured {
	.featured-list {
		display: flex;
		justify-content: center;
		flex-wrap: wrap;
		width: calc(100% + 2rem);
		margin: 0 -1rem;
	}

		.list-brand {
			width: 10%;
			// width: calc(100% / 8);
			padding: .5rem;

			@include media {
				width: 50%;
			}
		}

			.brand-link {
				display: block;
				padding: 1rem 2rem;
				text-align: center;

				&:hover {
					&:before {
						opacity: 1;
					}
				}
				
				&:before {
					@include pseudo;
					opacity: 0;
					position: abs($number: 0);
					position: absolute;
					z-index: 1;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					border: .1rem solid $color-gray-light;
					box-shadow: $shadow-general;
					transition: opacity $transition-hover ease;
					border-radius: $radius-general;
				}
				
				> * {
					position: relative;
					z-index: 2;
				}
			}

				.brand-imagewrap {
					position: relative;
					padding-bottom: 70%;
				}

			.brand-image {
				position: absolute;
				width: 100%;
				height: 100%;
			}
}

.section.brands-alphabeticlist {
	margin-top: 6rem;

	.alphabeticlist-groups {
		display: flex;
		flex-wrap: wrap;
		width: calc(100% + 2rem);
		margin: 1rem -1rem;
	}

		.groups-group {
			width: 20%;
			padding: 1rem 1rem 3rem;

			@include media {
				width: 50%;
			}
		}

			.group-title {
				@include font-bold;
				font-size: 2.6rem;
				display: block;
				margin-bottom: 1.5rem;

				&.placeholder {
					width: 2.6rem;
					height: 2.6rem;
				}
			}

			// .group-list {}

				.list-brand {
					+ .list-brand {
						margin-top: 1rem;
					}

					&.placeholder {
						height: 2rem;
						width: 60%;

						&:nth-child(4n+2) {
							width: 50%;
						}
						&:nth-child(4n+3) {
							width: 30%;
						}
						&:nth-child(4n+4) {
							width: 35%;
						}
					}
				}
}